import React from 'react'
import styled from 'styled-components'

import ContentDefault from '../components/container'
import Layout from '../components/layout'
import banner from '../images/banner.png'

const Banner = styled.div`
  width: 100%;
  height: 160px;
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

const Content = styled(ContentDefault)`
  padding: 50px 0;
  min-height: 750px;
`

const Title = styled.h1`
  color: #102549;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
`

const Subtitle = styled.h2`
  font-size: 26px;
  font-weight: 600;
  margin-top: 24px;
`

const Year = styled.h4`
  font-size: 18px;
  font-weight: 500;
  margin-top: 18px;
  padding-left: 20px;
`

const Link = styled.a.attrs({
  target: '_blank',
})`
  margin-bottom: 0.5rem;
`

const Transparencia = () => (
  <Layout title='Transparência' page='transparencia'>
    <Banner />
    <Content>
      <Title>Transparência</Title>

      <Subtitle>Geral</Subtitle>

      <ul style={{ margin: 0 }}>
        <li>
          <Link href='/estatuto.pdf'>Estatuto</Link>
        </li>
        <li>
          <Link href='/estatuto.pdf'>Balanço Patrimonial</Link>
        </li>
        <li>
          <Link href='/estatuto.pdf'>Relatório da Administração – 2019</Link>
        </li>
      </ul>

      <Subtitle>Demonstração do Fluxo de Caixa</Subtitle>

      <Year>2020</Year>

      <ul style={{ margin: 0 }}>
        <li>
          <Link href='/demonstracoes-caixa/2020/02. Fevereiro de 2020.pdf'>
            Fevereiro
          </Link>
        </li>
        <li>
          <Link href='/demonstracoes-caixa/2020/01. Janeiro de 2020.pdf'>Janeiro</Link>
        </li>
      </ul>

      <Year>2019</Year>

      <ul style={{ margin: 0 }}>
        <li>
          <Link href='/demonstracoes-caixa/2019/12. Dezembro de 2019.pdf'>Dezembro</Link>
        </li>
        <li>
          <Link href='/demonstracoes-caixa/2019/11. Novembro de 2019.pdf'>Novembro</Link>
        </li>
        <li>
          <Link href='/demonstracoes-caixa/2019/10. Outubro de 2019.pdf'>Outubro</Link>
        </li>
        <li>
          <Link href='/demonstracoes-caixa/2019/09. Setembro de 2019.pdf'>Setembro</Link>
        </li>
        <li>
          <Link href='/demonstracoes-caixa/2019/08. Agosto de 2019.pdf'>Agosto</Link>
        </li>
        <li>
          <Link href='/demonstracoes-caixa/2019/07. Julho de 2019.pdf'>Julho</Link>
        </li>
        <li>
          <Link href='/demonstracoes-caixa/2019/06. Junho de 2019.pdf'>Junho</Link>
        </li>
        <li>
          <Link href='/demonstracoes-caixa/2019/05. Maio de 2019.pdf'>Maio</Link>
        </li>
        <li>
          <Link href='/demonstracoes-caixa/2019/04. Abril de 2019.pdf'>Abril</Link>
        </li>
        <li>
          <Link href='/demonstracoes-caixa/2019/03. Março de 2019.pdf'>Março</Link>
        </li>
        <li>
          <Link href='/demonstracoes-caixa/2019/02. Fevereiro de 2019.pdf'>
            Fevereiro
          </Link>
        </li>
        <li>
          <Link href='/demonstracoes-caixa/2019/01. Janeiro de 2019.pdf'>Janeiro</Link>
        </li>
      </ul>
    </Content>
  </Layout>
)

export default Transparencia
